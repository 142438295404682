import styled from 'styled-components';

export * from '@nehalist/gatsby-theme-nehalem/src/components/subheader/style';

export const StyledSubheader = styled.div`
	display: flex;
	align-items: center;
	height: 90px;
`;

export const SubheaderTitle = styled.h1`
	font-size: 2em;
	font-weight: bold;
	color: #000;
	margin: 0;
	line-height: 1em;
`;
